<template>
  <div>
  </div>
</template>

<script>
export default {
  // name: "",
  data() {
    return {
    }
  },
  computed: {},

  components: {},

  methods: {
  },

  destroyed() {
  },

  mounted() {
    window.open("about:blank", "_self");
    window.close();
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
</style>